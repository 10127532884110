
  import { API, requests } from '@/api/apis'
  import { onMounted, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import MyTxt from './components/ShowTxt.vue'
  import MyShowTable from './components/MyShowTable.vue'
  import MyAttachmentsJson from './components/MyAttachmentsJson.vue'
  import { notification } from 'ant-design-vue'
  import MyUpload from '@/components/MyUpload/file.vue'
  import { FolderOutlined, DeleteOutlined } from '@ant-design/icons-vue'

  export default {
    components: {
      MyShowTable,
      MyTxt,
      MyUpload,
      MyAttachmentsJson,
      FolderOutlined,
      DeleteOutlined,
    },
    setup() {
      const attachments_json = ref([])
      const router = useRouter()
      const route = useRoute()
      const projectInfo: any = ref({})
      const project_data_arr = ref([])
      const getProject = (data: any) => {
        requests({
          url: API.project_graph_applies.Show,
          data: data,
        })
          .then((res: any) => {
            const data = res.data as any
            projectInfo.value = data
            console.log(res, 'skk')
          })
          .catch((err) => {
            console.log(err)
          })
      }
      const goPage = () => {
        router.go(-1)
      }
      const onSubmit = () => {
        let st = false
        // let list = JSON.parse(projectInfo.value.contents_json)
        // list.forEach((res: any) => {
        //   res.content.forEach((json: any) => {
        //     if ((!json.info || json.info.length == 0) && !st) {
        //       notification['warning']({
        //         message: '提示',
        //         description: '请上传成果！',
        //       })
        //       st = true
        //     }
        //     console.log(json, 'skk222')
        //   })
        // })
        let data = {
          pictures_json: projectInfo.value.contents_json,
          project_graph_applies_id: projectInfo.value.id,
          attachments_json:JSON.stringify(attachments_json.value)
        }
        if (!st) {
          requests({
            url: API.project_graph_accepts.Store,
            data: data,
          })
            .then((res: any) => {
              notification['success']({
                message: '提示',
                description: '发起成功！',
              })
              goPage()
              console.log(res, 'skk')
            })
            .catch((err) => {
              console.log(err)
            })
        }
        console.log(data, '提交')
      }
      const onChangeResults = (table_value: any) => {
        projectInfo.value.contents_json = table_value
        console.log(projectInfo, table_value, 'cds')
      }

      const uploadImgFun = (url: any) => {
        const data = [{ url: url, name: '' }]
        ;(attachments_json.value as any) = [...attachments_json.value, ...data]
      }
      const deleteAnnexFun = (idx: number) => {
        attachments_json.value.splice(idx, 1)
        console.log(idx, 'skks')
      }
      onMounted(() => {
        getProject(route.query)
      })
      return {
        projectInfo,
        project_data_arr,
        goPage,
        onSubmit,
        onChangeResults,
        uploadImgFun,
        attachments_json,
        deleteAnnexFun,
      }
    },
  }
